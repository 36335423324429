<template>
  <div class="container-question text-left">
    <template v-for="(data, index) in questions">
      <div
        :key="data.id"
        class="d-flex justify-content-between align-items-center item"
      >
        <u
          class="mb-0 cursor-pointer"
          :aria-controls="data.id"
          :aria-expanded="data.isActive"
          @click="active(index)"
          v-html="data.question"
        ></u>
        <span @click="active(index)" :id="`quest-${index}`">
          <img
            :aria-controls="data.id"
            :aria-expanded="data.isActive"
            :src="require('@/assets/images/icons/ic_arrow_down.svg')"
            :style="data.isActive ? 'transform: rotate(180deg)' : ''"
          />
        </span>
      </div>
      <b-collapse
        :id="data.id"
        :key="'answer-' + index"
        v-model="data.isActive"
      >
        <p class="quick-answer font-weight-thin" @click="handleLineClick" v-html="data.answer"></p>
      </b-collapse>
    </template>
    <div class="d-flex justify-content-between align-items-center item">
      <u
        aria-controls="report-issue"
        :aria-expanded="isActiveReportIssue"
        @click="activeReportIssue()"
        class="mb-0 cursor-pointer"
      >
        Need to report an issue?
      </u>
      <span @click="activeReportIssue()" id="quest-2">
        <img
          aria-controls="report-issue"
          :aria-expanded="isActiveReportIssue"
          :src="require('@/assets/images/icons/ic_arrow_down.svg')"
          :style="isActiveReportIssue ? 'transform: rotate(180deg)' : ''"
        />
      </span>
    </div>
    <b-collapse id="report-issue" v-model="isActiveReportIssue">
      <p class="quick-answer font-weight-thin">
        If you experience any issues with what you see on this page, please
        contact the {{ brandName }} team.<br />
        <u v-b-modal.modal-issue style="cursor: pointer">Report an issue</u>
      </p>
    </b-collapse>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "QuestionAndAnswer",
  data() {
    return {
      isActiveReportIssue: true,
    };
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      brand: (state) => state.commonVariables.brandName,
    }),
    brandName() {
      return this.brand || "Autoflip";
    },
  },
  methods: {
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
    handleLineClick(e) {
      const clickedId = e.target.id;
      if (clickedId === 'jiraIssueLink') {
        // this.openJiraIssue(e);
        this.$bvModal.show("modal-issue");
      }
    },
    activeReportIssue() {
      this.isActiveReportIssue = !this.isActiveReportIssue;
    },
    openJiraIssue(e) {
      e.preventDefault();
      window.showCollectorDialog();
    },
  },
};
</script>
<style lang="scss">
.container-question {
  max-width: 345px;
  padding: 0 10px;
  margin: 40px auto 30px;
  .item {
    margin-bottom: 0.5rem;
    u {
      color: var(--primary-color) !important;
    }
    span {
      .not-collapsed {
        transform: rotate(180deg);
        transition: ease-in-out 0.3s;
      }
      .collapsed {
        transition: ease-in-out 0.3s;
      }
    }
    img {
      width: 14px;
      height: 8px;
      cursor: pointer;
      margin-left: 5px;
      transition: all 0.5s;
    }
  }
  .quick-answer {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #6f6f6f;
    u {
      font-family: Avenir Next LT W05 Regular !important;
      font-weight: 700 !important;
      color: #0f0f0f;
    }
  }
}
@media screen and(min-width: 900px) {
  .container-question {
    padding: 0 !important;
    margin-left: 0 !important;
    span {
      &:hover {
        transform: translateY(-1.5px);
        transition: ease-in-out 0.2s;
      }
    }
  }
}
@media screen and(max-width: 991px) {
  .container-question {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>